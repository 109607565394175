.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    margin-top: 10%;
}

.container ion-note {
    margin-top: 10px;
}

.spinner-container {
    height: 100%;
    width: 100%;
    
    display: flex;
    justify-content: center;
    align-items: center;
}

.actions-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.actions-container .action-button {
    width: 70%;
    max-width: 300px;
}

.font-style-italic {
    font-style: italic;
}
